import React, { useState } from 'react';
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { browseBtnService } from "services/browseBtnService";
import { useTranslation } from "react-i18next";


const BrowseButtonArticleImage = ({ setFieldValue,values }) => {
let responseImagePath;

  //As the system measures the size in decimal format we have to user 1000kb as 1MB
  const FILE_SIZE = 10 * 1000 * 1000; // 10MB in bytes
  //const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];
  const [error, setError] = useState('');

  const previewCOD = (event) => {
    setError('')
    setError('')    
    const file = (event.target.files[0]);
    if (file.size > FILE_SIZE) {
      setError('Image size should be less than 10MB')
    } else {
      setPictureBrowseBtn(file);
      setBrowseBtn(file);
      setBrowseBtnFilename(file);
      console.log("file name",file.name)
      setURLname(file.name);
    }

  }

  const [BrowseBtn, setBrowseBtn] = React.useState("");
  const [BrowseBtnFilename, setBrowseBtnFilename] = React.useState("");
  const [BrowseBtnImport, setBrowseBtnImport] = React.useState("");
  const [urlname, setURLname] =useState("");

  const { t } = useTranslation();

  function setPictureBrowseBtn(urlName) {
    browseBtnService.getPictureBrowseBtn(urlName).then((response) => {
      console.log("response", response);
      responseImagePath = response.imagePath;
      console.log("responseImagePath", responseImagePath);
      setFieldValue("imageUrl",responseImagePath)      
    })
  }
   console.log('valuesimg',values);
  const ColorButton = withStyles(theme => ({
    root: {
      backgroundImage: "linear-gradient(to bottom, #34409A, #14AAE4)",
      width: "100%",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "linear-gradient(to bottom, #34409A, #14AAE4)"
      }
    }
  }))(Button);


  const resetHandler = (event) => {
    setBrowseBtnImport("");
    setBrowseBtnFilename(""); 
    setBrowseBtnFilename("");   
  }

  //render() {
  return (
    <div>

      <Grid item container justify="space-between" alignItems="center"
        style={{ padding: "3em" , marginLeft: '10%', marginTop: '-9%'}}>   
        <Grid item container md={4} alignItems="center">
        </Grid>
        <Grid item md={3}>
         <img src={values.imageUrl} alt='No Image' style={{width:'100px',height:'100px'}} />
        </Grid>
        
        <Grid item md={4} xs={12}>
          {BrowseBtn ?
            <div>
                <header style={{ fontSize: "12px", color: "#166aab" }}>{responseImagePath}</header>
                <div style={{ display: "flex", marginTop: "10px" }}>
                <div style={{ width: "50%", marginRight: "3px" }}>
                </div>
                <div style={{ width: "50%", marginLeft: "3px" }}>
                </div>
              </div>
            </div> :
            <Tooltip title="Image Size should be less than 10 MB" placement="top">
              <ColorButton variant="contained"
                color="primary"
                component="label"
                className="align-self-end w-50 text-capitalize">                  
                Browse Image
                <Input type="file" onChange={previewCOD}
                  style={{ display: "none" }}
                  //inputProps={{ accept: ".xlsx, .xls, .csv"}}
                  inputProps={{ accept: ".jpg, .jpeg" }}
                  name="BrowseBtnImport" value={BrowseBtnImport} />
              </ColorButton>
            </Tooltip>
          }
          {responseImagePath == undefined && urlname != ""?
          <ColorButton variant="contained"
          color="primary"
          component="label"
          className="align-self-end w-50 text-capitalize">
          Browse Image
          <Input type="file" onChange={previewCOD}
            style={{ display: "none" }}
            //inputProps={{ accept: ".xlsx, .xls, .csv"}}
            inputProps={{ accept: ".jpg, jpeg" }}
            name="BrowseBtnImport" value={BrowseBtnImport} />
        </ColorButton>
        :null
          }
          {error && <p style={{ color: 'red', whiteSpace: 'nowrap', marginLeft: "-50px" }}>{error}</p>}

        </Grid>
      </Grid>     

    </div>
    
    );

  //}

}

export default BrowseButtonArticleImage;