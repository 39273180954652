import React, { useEffect, useState, useContext } from "react";
import ArticleForm from './ArticleForm';
import { provisioningService } from "services/provisioningServices";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import SweetAlert from 'react-bootstrap-sweetalert';
import { articleService } from "services/articleService";
import { useParams, useHistory } from "react-router-dom";
import ArticleHirarchy from "components/common/form/ArticleHirarchy";

export default function CreateArticle() {  
  const { t } = useTranslation();
  const history = useHistory();
  const [language, setLanguage] = React.useState([]);
  const {dispatch: errorDispatch } = useContext(errorContext);
  const [parentId, setParentId] = React.useState([]);

  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");
  const [urlError,setUrlError] = useState("");   
  
  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getListData();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

  function getListData(value){   

    notificationService.getNotificationUIOptions('LANGUAGE').then(data => {
      if (data) {
        setLanguage(data);
      }
    });
  }

  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  } 
  
  // Main Article

//   let values = {     
//     name: "",
//     imageUrl: "",
//     languageList: [
//         {
//             language: "ENGLISH"
//         }
//     ],
//     parentId: "",
//     priority: "",
//     isActive: "",    
//     supportedOrgIdList: ""    
// };

// Main category

let values = {     
  name: "",
  imageUrl: "",
  languageList: [
      {
          language: "ENGLISH"
      }
  ],
  parentId: "",
  priority: "",
  isActive: "",    
  supportedOrgIdList: ""    
};

  async function handleSubmit(values, resetForm, setSubmitting) 
  { 
    console.log('openeddd');
    for (let i = 0; i < values.languageList.length; i++)
    {
      let languageObject = values.languageList[i];      
      if (languageObject.language != undefined){
        if(languageObject.language === "ENGLISH") 
        {       
          if (languageObject && typeof languageObject === 'object')
          {
            { 
              let enURL = languageObject.url;
                if (enURL != undefined){
                values.en = enURL
                setUrlError("");
              }
              else{
                setUrlError("Please enter the URL");
                // resetForm(values, true);
                return;              
              }
            }          
          }
        }
      else if(languageObject.language === "KANNADA")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let kaURL = languageObject.url;
          if(kaURL != undefined){
            values.kn = kaURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "HINDI")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let hiURL = languageObject.url;
          if (hiURL != undefined){
            values.hi = hiURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "MARATI")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let mrURL = languageObject.url;
          if (mrURL != undefined){
            values.mr = mrURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "TELUGU")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let teURL = languageObject.url;
          if (teURL != undefined){
            values.te = teURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "TAMIL")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let taURL = languageObject.url;
          if (taURL != undefined){
            values.ta = taURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
    }
    else
    {
      setUrlError("Please select the Language");
      resetForm(values, true);
      return;   
    } 

    

    values.languages = {};

    if (values.en !== undefined) {   
        values.languages.en = {
          "url": values.en,
          "name":values.languageList[0].name          
        }
    }     
    if (values.kn !== undefined) {   
        values.languages.kn = {
            "url": values.kn,
            "name":values.languageList[1].name            
        }
    }
    if (values.hi !== undefined) {   
        values.languages.hi = {
            "url": values.hi,
            "name":values.languageList[2].name            
        }
    }
    if (values.mr !== undefined) {   
        values.languages.mr = {
            "url": values.mr,
            "name":values.languageList[3].name            
        }
    }
    if (values.te !== undefined) {   
        values.languages.te = {
            "url": values.te,
            "name":values.languageList[4].name            
        }
    }
    if (values.ta !== undefined) {   
        values.languages.ta = {
            "url": values.ta,
            "name":values.languageList[5].name            
        }        
    }  

    } 
   
    console.log("Clicked",values);
    try {
      const repeat = null;
      const result = await articleService.ArticleCreate(values);
      console.log("Result:============= ", result);
      if(result.data === null && result.message && result.status === "failure"){
        //console.log("If Condition:==========")
        //setCreateFailure(true);
        //setFailureMsg(result.message);
        setSubmitting(false);
      }else{

        try {
          const repeat = null;          
          resetForm();
          setCreateSuccess(true);
          setTimeout(() => {
            history.push("/configuration/article_listHierarchy");
          }, 3000);

        } catch (e) {
          // console.error(e);
          // setCreateFailure(true);
          // setFailureMsg(e.error.message);
          // setSubmitting(false);
        }

      }
    } catch (e) {
      //console.error(e);
    }
    
  }

  return (
    <>
    <grid >             
      <ArticleForm
        page="add"
        values={values}            
        language={language}          
        parentId = {parentId}  
        handleSubmit={handleSubmit}
      />       
    </grid>


    
    {createSuccess ? 
      <SweetAlert success
        title="Article Created Successfully"
        onConfirm={hideAlert}
        //disabled={ disableOkBtn ? true : false }
        onCancel={hideAlert}
        timeout={2000}
      ></SweetAlert> 
    : null}

    {createFailure ? 
      <SweetAlert danger
        title={failureMsg}
        onConfirm={hideAlert}
        //disabled={ disableOkBtn ? true : false }
        onCancel={hideAlert}
        //timeout={3000}
      ></SweetAlert> 
    : null}

</>

  );
}