import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PageHeader from "../../../components/layout/PageHeader.js";
import { approveServices } from "../../../services/approveServices.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import ApproveFeedback from "components/common/elements/ApproveFeedback";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";
import ConfirmPopUp from "components/common/elements/ConfirmPopUp";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from "components/common/table/DynamicPaginationTable2";
import { useLocation } from 'react-router-dom'; 
import ViewCategory from "./ViewCategory";
import { GetAppOutlined } from "@material-ui/icons";
import { CustomizedPrimaryButton } from "components/common/elements/CustomizedPrimaryButton";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SweetAlert from 'react-bootstrap-sweetalert';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tabIndicator: {
    backgroundColor: "#1089ff"
  }
}));

export default function ApproveList() {

  const user = JSON.parse(localStorage.getItem("currentUser"));

  const { t } = useTranslation();
  const { dispatch: errorDispatch } = useContext(errorContext);
  const classes = useStyles();
  const [change, setChange] = useState(false);
  const [value, setValue] = React.useState(0);
  const [mooRetailvalue,setMooRetailvalue] = React.useState(0);
  const [appTypeValue, setAppTypeValue] = React.useState(0);
  const [smartFarmCategoryData,setSmartFarmCategoryData] = useState([]);
  const [smartFarmPriceData,setSmartFarmPriceData] = useState([]);
  const [smartFarmProductData,setSmartFarmProductData] = useState([]);
  const [approveDataStatus, setApproveDataStatus ] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [mooRetailPriceData,setMooRetailPriceData] = useState([]);
  const [mooRetailCategoryData,setMooRetailCategoryData] = useState([]);
  const [mooRetailProductData,setMooRetailProductData] = useState([]);

  const [filteredAppTypeData,setFilteredAppTypeData] = useState([]);
 
  const approveStatus = ["PENDING","APPROVED","REJECTED"];

  const [selectedAppType, setSelectedAppType] = useState("");
  const [selectedItemType, setSelectedItemType] = useState("");
  const [itemStatus,setItemStatus] = useState("");
  const [statusSelectedData,setStatusSelectedData] = useState([]);
  const [failureCount,setFailureCount] = useState();
  const [selectedApproveStatus,setSelectedApproveStatus] = useState("PENDING");

  const [bulkStatusMessage,setBulkStatusMessage] = useState([]);
  const [isBulkStatusMessage,setIsBulkStatusMessage] = useState(false);
  const [isBulkSuccessMessage,setIsBulkSuccessMessage] = useState(false);

  const formikForm = React.useRef(null);

  const [] = useState([]);

  const location = useLocation();
  let values = {
    approveStatus: approveStatus[0]
  };  

  const [smartFarmsUser,setSmartFarmsUser] = useState(false);
  const [mooRetailUser,setMooRetailUser] = useState(false);
  const [smartFarmsProductTab,setSmartFarmsProductTab] =useState(false);
  const [smartFarmsCategoryTab,setSmartFarmsCategoryTab] = useState(false);
  const [smartFarmsPriceTab,setSmartFarmsPriceTab] =  useState(false);
  const [mooRetailProductTab ,setMooRetailProductTab]= useState(false);
  const [mooRetailCategoryTab,setMooRetailCategoryTab] = useState(false);
  const [mooRetailPriceTab ,setMooRetailPriceTab]= useState(false);
  const history = useHistory();

  function getPrivilegeUserDetails(){
    if(user.uiPrivileges.includes("APPROVE_SMARTFARMS")) {
      setSmartFarmsUser(true);
    } 
    if(user.uiPrivileges.includes("APPROVE_MOORETAIL")){
      setMooRetailUser(true);
    }
    if(user.uiPrivileges.includes("SMARTFARMS_PROCUCT")){
      setSmartFarmsProductTab(true); 
    }
    if(user.uiPrivileges.includes("SMARTFARMS_CATEGORY")){
      setSmartFarmsCategoryTab(true);
    }
    if(user.uiPrivileges.includes("SMARTFARMS_PRICE")){
      setSmartFarmsPriceTab(true);
    }
    if(user.uiPrivileges.includes("MOORETAIL_CATEGORY")){
      setMooRetailCategoryTab(true);
    }
    if(user.uiPrivileges.includes("MOORETAIL_PRODUCT")){
      setMooRetailProductTab(true);
    }
    if(user.uiPrivileges.includes("MOORETAIL_PRICE")){
      setMooRetailPriceTab(true);
    }
  }

  function getDataBasedOnAppType(){
    if(localStorage.getItem("appTypeTabValue") != null){
      let tabValue = JSON.parse(localStorage.getItem("appTypeTabValue"));
      if(tabValue === 0){
        setSelectedAppType("smartFarms");
        getApproveDetails(values,"smartFarms");
      }else{
        setSelectedAppType("mooRetail");
        getApproveDetails(values,"mooRetail");
      }
    }else if(smartFarmsUser === true){
      setSelectedAppType("smartFarms");
      getApproveDetails(values,"smartFarms");
    }else if(mooRetailUser === true){
      setSelectedAppType("mooRetail");
      getApproveDetails(values,"mooRetail");
    }
  }
  
  useEffect(() => {
    getPrivilegeUserDetails(); 
  }, []);

  useEffect(() =>{
    if(localStorage.getItem("appTypeTabValue") != null){
      setAppTypeValue(JSON.parse(localStorage.getItem("appTypeTabValue")));
    }else {
      if(smartFarmsUser === false && mooRetailUser === true){
        setAppTypeValue(1);
      }else{
        setAppTypeValue(0);
      }
    }

    if(history.location.state != null){
      setValue(parseInt(history.location.state.from));
    }else if(localStorage.getItem("smartFarmsTabValue") != null){
      setValue(JSON.parse(localStorage.getItem("smartFarmsTabValue")));
    }else {
      if(smartFarmsCategoryTab === false && smartFarmsProductTab === true){
        setValue(1);
      }else if(smartFarmsCategoryTab === false && smartFarmsProductTab === false && smartFarmsPriceTab === true ){
        setValue(2);
      }else{
        setValue(0);
      }
    }

    if(history.location.state != null){
      setMooRetailvalue(parseInt(history.location.state.from));
    }else if(localStorage.getItem("mooRetailTabValue") != null){
      setMooRetailvalue(JSON.parse(localStorage.getItem("mooRetailTabValue")));
    }else {
      if(mooRetailCategoryTab === false && mooRetailProductTab === true){
        setMooRetailvalue(1);
      }else if(mooRetailCategoryTab === false && mooRetailProductTab === false && mooRetailPriceTab === true ){
        setMooRetailvalue(2);
      }else{
        setMooRetailvalue(0);
      }
    }
    getDataBasedOnAppType();
  }, [smartFarmsUser,mooRetailUser,smartFarmsCategoryTab,smartFarmsProductTab,smartFarmsPriceTab,mooRetailProductTab,mooRetailCategoryTab,]);

  const fields = [
    {
      type: "select",
      name: "approveStatus",
      label: t("approve_product_Form_status_Label"),
      placeholder: t("approve_product_Form_status_Label"),
      option: approveStatus.length > 0
        ? approveStatus.map(approveStatus => ({
            value: approveStatus,
            label: approveStatus
          }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 4,
        className: ""
      }
    }
  ];  
  
  let validations = {
    approveStatus:Yup.string().required(t("approve_Form_Status_Mandatory_Label"))
  };

  function invokeGetDataByAppType(itemType,itemStatus,appType){
    approveServices.getDataByAppType(itemType,itemStatus,appType).then(data => { 
      if(appType === "smartFarms"){    
        if(itemType === "price"){
          setSmartFarmPriceData(data);
        }
      }else if(appType === "mooRetail"){
        if(itemType === "price"){
          setMooRetailPriceData(data);
        }
      }
      setApproveDataStatus("success");
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }

  function invokeApproveDataByStatus(itemType,values){
    approveServices.getApproveData(itemType,itemStatus).then(data => { 
      if(itemType === "category"){
        setSmartFarmCategoryData(data);
        setMooRetailCategoryData(data);
      }else if(itemType === "product"){
        setSmartFarmProductData(data);
        setMooRetailProductData(data);
      }      
      setApproveDataStatus("success");
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }
 
  function getApproveDetails(values,selectedAppType){
    getDataByStatus(values);
    invokeGetDataByAppType("price",values.approveStatus,selectedAppType);
  }

  function getDataByStatus(values){
    approveServices.getApproveListByStatus(values.approveStatus).then(data => {
      if (data) {
        let catData  = data.filter(data => {
          return data.entityType === 'item-group-create' || data.entityType === 'item-group-update';
        });
        setSmartFarmCategoryData(catData);
        setMooRetailCategoryData(catData);
      
       let prodData = data.filter(data => {
          return data.entityType === 'item-update' || data.entityType === 'item-create';
        });

        setSmartFarmProductData(prodData);
        setMooRetailProductData(prodData);

        setApproveDataStatus("success")

        setFilteredAppTypeData(data.filter(data => {
          return (data.entityType === 'item-price-create' || data.entityType === 'item-price-update') && data.after.appType === "smartFarms" ;
        }));
      }
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }

  function handleSubmit(values, resetForm, setSubmitting) {
    if(values.approveStatus === undefined){
      setShowErrorMessage(true);
      return;
    }
    // setApproveCheckbox(false);
    // if(values.approveStatus === "PENDING"){
    //   setApproveCheckbox(true);
    // }
    setSelectedApproveStatus(values.approveStatus);
    localStorage.setItem("approveStatus",values.approveStatus);
    getApproveDetails(values,selectedAppType);
    setSubmitting(false);
  }
  
  const handleChange = (event, newValue) => {
    setAppTypeValue(newValue);
    setStatusSelectedData([]);
    localStorage.setItem("appTypeTabValue",newValue);
    setMooRetailvalue(0);
    if(smartFarmsUser === false && mooRetailUser === true){
      setAppTypeValue(1);
    }
    setValue(0);
    if(newValue === 0){
      setSelectedAppType("smartFarms");
      getApproveDetails(values,"smartFarms");

    }else{
      setSelectedAppType("mooRetail");
      getApproveDetails(values,"mooRetail");
    }
  };

  const handleTabChange = (event ,newValue) =>{
    setValue(newValue);
    if(smartFarmsUser === true && mooRetailUser === false){
      if(smartFarmsProductTab === false && smartFarmsCategoryTab === false && smartFarmsPriceTab === true){
        setValue(2);
      }
    }
    localStorage.setItem("smartFarmsTabValue",newValue);
    if(newValue === 2){
      setSelectedItemType("price");
    }
  }

  const handleMooRetailTabChange = (event ,newValue) =>{
    setMooRetailvalue(newValue);
    if(mooRetailUser === true && smartFarmsUser === false){
      if(mooRetailProductTab === false && mooRetailCategoryTab === false && mooRetailPriceTab === true ){
        setMooRetailvalue(2);
      }
    }
    localStorage.setItem("mooRetailTabValue",newValue);
    if(mooRetailvalue === 2){
      setSelectedItemType("price");
    }
  }

  function statusSelected(e,data){
    console.log("checkbox handle===================", e.target.checked);
    console.log("Data from select================",data);
    if(e.target.checked === true){
      statusSelectedData.push(data.id);
     // setStatusSelectedData([...statusSelectedData, data.id]);
    }else {
      let index = statusSelectedData.findIndex(tmpData => tmpData === data.id);
      statusSelectedData.splice(index, 1);
      
      // let filteredData = statusSelectedData;
      // console.log("filtered data ====== ", filteredData);
      // filteredData.splice(index,1);
      // console.log("splice data ===", filteredData);
      // setStatusSelectedData(filteredData);
    }
    console.log("statusSelectedData hook ================ ", statusSelectedData);
    
  }

  console.log("statusSelectedData global hook ================ ", statusSelectedData);
  const [approveCheckbox,setApproveCheckbox] = useState(true);

  const priceColumns = React.useMemo(() => [
    // approveCheckbox ? 
    { 
      Header: "Select" ,
      Cell: ({ row }) => {
        let link = `/configuration/editnotification/${row.original.configurationId}`;
        return (
          <input type="checkbox" key={row.original.id} checked={row.checked} onChange={ (e) => statusSelected(e,row.original)}></input>
          
        )
      }
    },
    //  : {
    //   Header:"",
    //   accessor:"after.ID"
    // },   
    {
      Header: "Product OMS Reference ID",
      accessor: "after.productOmsReferenceId"
    },
    { 
      Header:"Product Name",
      accessor:"after.productName"
    },
    {
      Header: "Territory",
      accessor: "after.mappingTag"
    },   
    {
      Header: "MRP",
      accessor: "after.mrp"
    },
    {
      Header: "Selling Price",
      accessor: "after.sellingPrice"
    },     
    {
      Header:"Reason",
      accessor:"response"
    },
    {
      Header:"Approve Status",
      accessor:"approvalStatus"
    },
    {
      Header: "View",
      Cell: ({ row }) => {
        let link = `/configuration/approve/price/${row.original.id}`;
        return (
          <>
             <Link to={{
                pathname: link,
                state: {
                  from: row.original
                }
              }}>
              <IconButton>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </IconButton>
            </Link>
          </>
        )
      }
    }
  ],
  [statusSelectedData]
);

  const productColumns =  React.useMemo(
    () => [
      {
        Header: "Image",
        Cell: ({ row }) => {
          return (
            <>
              <img src={row.original.after.imageURL}  width="50" height="50"/>
            </>
          )
        }
      },
      {
        Header: "Product Name",
        accessor: "after.productName"
      },
      {
        Header: "Product Code",
        accessor: "after.productCode"
      },
      {
        Header: "Item Group",
        accessor: "after.itemGroup"
      },
      {
        Header: "Rating",
        accessor: "after.rating"
      },
      {
        Header: "View",
        Cell: ({ row }) => {
          let link = `/configuration/approve/product/${row.original.id}`;
          return (
            <>
              <Link to={{
                pathname: link,
                state: {
                    from: row.original
                }
              }}>
                <IconButton>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </IconButton>
              </Link>
            </>
          )
        }
      }
    ],
    []
  );

  const categoryColumns = React.useMemo(
    () => [
      {
        Header: "Image",
        Cell: ({ row }) => {
          return (
            <>
              <img src={row.original.after.imageUrl}  width="50" height="50"/>
            </>
          )
        }
      },
      {
        Header: "Parent Id",
        accessor: "after.parentId"
      },
      {
        Header: "Search Tag",
        accessor: "after.searchTag"
      },
      {
        Header: "Description",
        accessor: "after.description"
      },
      {
        Header: "Hierarchy Tree",
        accessor: "after.hierarchyTree"
      },
      {
        Header: "Item Group Name",
        accessor: "after.categoryName"
      },
      {
        Header: "View",
        Cell: ({ row }) => {
          let link = `/configuration/approve/category/${row.original.id}`;
          return (
            <>
              <Link to={{
                pathname: link,
                state: {
                    from: row.original
                }
              }}>              
                <IconButton>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </IconButton>
              </Link>
            </>
          )
        }
      }
    ],
    []
  );
  
  const smartFarmPriceTableData = React.useMemo(() => [...smartFarmPriceData]);
  const smartFarmCategoryTableData = React.useMemo(() => [...smartFarmCategoryData]);
  const smartFarmProdTableData = React.useMemo(() => [...smartFarmProductData]);

  const mooRetailPriceTableData = React.useMemo(() => [...mooRetailPriceData]);
  const mooRetailCategoryTableData = React.useMemo(() => [...mooRetailCategoryData]);
  const mooRetailProdTableData = React.useMemo(() => [...mooRetailProductData]);

  const handleDialogClose = () => {    
    setIsBulkStatusMessage(false);
    setIsBulkSuccessMessage(false);
    getApproveDetails(values,selectedAppType);
  };

  const hideAlert = () =>{
    setIsLoadingAPI(false);
  }

  const handleStatusAlertClose = () => {
    setSelectStatusAlert(false);
  }

  const invokeBulkApproveAPI = () => {
    setIsConfirmStatus(false);
    approveRejectPriceData("APPROVED");
  }

  const invokeBulkRejectAPI = () => {
    setIsRejectConfirmStatus(false);
    approveRejectPriceData("REJECTED");
  }

  const handleConfirmDialogClose = () => {
    setIsConfirmStatus(false);
    setIsRejectConfirmStatus(false);
  }

  const [selectStatusAlert,setSelectStatusAlert] = useState(false);
  const [isConfirmStatus,setIsConfirmStatus] = useState(false);
  const [successMessageForApproveReject,setSuccessMessageForApproveReject] = useState("");
  const [isLoadingAPI ,setIsLoadingAPI] = useState(false);
  const [isRejectConfirmStatus,setIsRejectConfirmStatus] = useState(false);

  function openApproveConfirmPopUp(){
    setIsConfirmStatus(true);
  }

  function openRejectConfirmPopUp(){
    setIsRejectConfirmStatus(true);
  }
  
  function approveRejectPriceData(selectedStatus){
    if(statusSelectedData.length === 0){
     setSelectStatusAlert(true);
     return;
    }
    setIsLoadingAPI(true);
    approveServices.bulkApproveRejectPriceData(selectedStatus,statusSelectedData).then(data => {
      setIsLoadingAPI(false);
      let statusData = [];
      setStatusSelectedData([]);
      if(selectedStatus === "APPROVED"){
        setSuccessMessageForApproveReject("Approved Successfully.")
      }else if(selectedStatus === "REJECTED"){
        setSuccessMessageForApproveReject("Rejected Successfully.");
      }
      for(let i =0;i<data.length;i++){
        if(data[i].status === "failure"){
          statusData.push(<div>{data[i].message}</div>);
        }
      }
      if(statusData.length === 0){
        setIsBulkSuccessMessage(true);
        // getApproveDetails(values,selectedAppType);
      }else{
        setFailureCount("Failure: "+statusData.length);
        setBulkStatusMessage(statusData);
        setIsBulkStatusMessage(true);
      }
    })
    .catch(error => {
      setIsLoadingAPI(false);
      errorDispatch({
        type: "ERROR",
        error
      });   
    });
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Approve" }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%",
          marginBottom: 10
        }}
      >
        <Grid>
          <Tabs
            value={appTypeValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Approve"
          >
            {smartFarmsUser ? (
              <Tab label="smartFarms" {...a11yProps(0)} />
            ) : null}

            {mooRetailUser ? <Tab label="mooRetail" {...a11yProps(1)} /> : null}
          </Tabs>

          <CommonForm
            fields={fields}
            submitLabel={t("site_Form_Submit_Label")}
            submittingLabel={t("site_Form_Submitting_Label")}
            initialValues={values}
            validationSchema={Yup.object().shape({ ...validations })}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(
              values,
              { setSubmitting, setFieldError, resetForm, errors }
            ) => {
              if (!!errors) {
                setSubmitting(false);
              }
              handleSubmit(values, resetForm, setSubmitting);
            }}
            formikRef={formikForm}
            buttonSize="2"
            buttonPosition="right"
            inlineButton={true}
          />

          {approveDataStatus === "success" ? (
            <>
              <TabPanel value={appTypeValue} index={0}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Approve"
                >
                  {smartFarmsUser && smartFarmsCategoryTab ? (
                    <Tab label="Category" {...a11yProps(0)} />
                  ) : null}
                  {smartFarmsUser && smartFarmsProductTab ? (
                    <Tab label="Product" {...a11yProps(1)} />
                  ) : null}
                  {smartFarmsUser && smartFarmsPriceTab ? (
                    <Tab label="Price" {...a11yProps(2)} />
                  ) : null}
                </Tabs>
                {smartFarmsUser && smartFarmsCategoryTab ? (
                  <TabPanel value={value} index={0}>
                    <Table
                      columns={categoryColumns}
                      data={smartFarmCategoryTableData}
                      needDropdown={false}
                      fileName={"approveList.xls"}
                    />
                  </TabPanel>
                ) : null}
                {smartFarmsUser && smartFarmsProductTab ? (
                  <TabPanel value={value} index={1}>
                    <Table
                      columns={productColumns}
                      data={smartFarmProdTableData}
                      needDropdown={false}
                      fileName={"approveList.xls"}
                    />
                  </TabPanel>
                ) : null}
                {smartFarmsUser && smartFarmsPriceTab ? (
                  <>
                    <TabPanel value={value} index={2}> 
                    {selectedApproveStatus === "PENDING" ? (
                    <div
                      style={{
                        float: "right",
                        display: "inline-flex",
                        marginBottom: "1%",
                        marginRight: "9%",
                      }}
                    >
                      <CustomizedPrimaryButton
                        onClick={() => openApproveConfirmPopUp()}
                        color="primary"
                        label={t("approve_Form_Bulk_Approve_Price_Label")}
                        width="1"
                        inlineButton="true"
                        position="right"
                      ></CustomizedPrimaryButton>
                      <CustomizedPrimaryButton
                        onClick={() => openRejectConfirmPopUp()}
                        color="primary"
                        label={t("approve_Form_Bulk_Reject_Price_Label")}
                        width="1"
                        inlineButton="true"
                        position="right"
                      ></CustomizedPrimaryButton>
                    </div>
                    ) : null}
                    {smartFarmPriceTableData && (
                      <Table
                        columns={priceColumns}
                        data={smartFarmPriceTableData}
                        needDropdown={false}
                        fileName={"approveList.xls"}
                      />
                    )}
                    </TabPanel>
                  </>
                ) : null}
              </TabPanel>

              <TabPanel value={appTypeValue} index={1}>
                <Tabs
                  value={mooRetailvalue}
                  onChange={handleMooRetailTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Approve"
                >
                  {mooRetailUser && mooRetailCategoryTab ? (
                    <Tab label="Category" {...a11yProps(0)} />
                  ) : null}
                  {mooRetailUser && mooRetailProductTab ? (
                    <Tab label="Product" {...a11yProps(1)} />
                  ) : null}
                  {mooRetailUser && mooRetailPriceTab ? (
                    <Tab label="Price" {...a11yProps(2)} />
                  ) : null}
                </Tabs>
                {mooRetailUser && mooRetailCategoryTab ? (
                  <TabPanel value={mooRetailvalue} index={0}>
                    <Table
                      columns={categoryColumns}
                      data={mooRetailCategoryTableData}
                      needDropdown={false}
                      fileName={"approveList.xls"}
                    />
                  </TabPanel>
                ) : null}

                {mooRetailUser && mooRetailProductTab ? (
                  <TabPanel value={mooRetailvalue} index={1}>
                    <Table
                      columns={productColumns}
                      data={mooRetailProdTableData}
                      needDropdown={false}
                      fileName={"approveList.xls"}
                    />
                  </TabPanel>
                ) : null}

                {mooRetailUser && mooRetailPriceTab ? (
                  <>
                    <TabPanel value={mooRetailvalue} index={2}>
                    {selectedApproveStatus === "PENDING" ? (
                    <div
                      style={{
                        float: "right",
                        display: "inline-flex",
                        marginBottom: "1%",
                        marginRight: "9%",
                      }}
                    >
                      <CustomizedPrimaryButton
                        onClick={() =>  openApproveConfirmPopUp()}
                        variant="contained"
                        color="primary"
                        label={t("approve_Form_Bulk_Approve_Price_Label")}
                        width="1"
                        position="right"
                      ></CustomizedPrimaryButton>
                      <CustomizedPrimaryButton
                        onClick={() => openRejectConfirmPopUp()}
                        variant="contained"
                        color="primary"
                        label={t("approve_Form_Bulk_Reject_Price_Label")}
                        width="1"
                        position="right"
                      ></CustomizedPrimaryButton>
                    </div>
                  ) : null}
                      <Table
                        columns={priceColumns}
                        data={mooRetailPriceTableData}
                        needDropdown={false}
                        fileName={"approveList.xls"}
                      />
                    </TabPanel>
                  </>
                ) : null}
              </TabPanel>
            </>
          ) : (
            <></>
          )}
        </Grid>
    
        {isLoadingAPI  ? 
          <SweetAlert success
            title="Please wait Processing"
            onConfirm={hideAlert}
            onCancel={hideAlert}
            timeout={2000}
          ></SweetAlert> 
        : null}
      </Paper>

      <ApproveFeedback
        open={isBulkStatusMessage}
        onClose={handleDialogClose}
        successMessage={failureCount}
        message={bulkStatusMessage}
        showButtons={false}
      />

      <SuccessFeedback
        open={isBulkSuccessMessage}
        onClose={handleDialogClose}
        successMessage={"Success"}
        message={successMessageForApproveReject}
        showButtons={false}
      />

      <FailureFeedback
        open={selectStatusAlert}
        onClose={handleStatusAlertClose}
        status={"Failed"}
        message={t("approve_Form_CheckBox_Select_Message")}
        showButtons={true}
      />   
      
      <ConfirmPopUp
        open ={isConfirmStatus}
        onSubmit={invokeBulkApproveAPI}
        confirmMessage={"Do you want Approve?"}
        onClose={handleConfirmDialogClose}
        showButtons={true}
      />

      <ConfirmPopUp
        open ={isRejectConfirmStatus}
        onSubmit={invokeBulkRejectAPI}
        confirmMessage={"Do you want Reject?"}
        onClose={handleConfirmDialogClose}
        showButtons={true}
      />

    </Grid>
  );
};
