
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PageHeader from "components/layout/PageHeader";
import CommonForm from "components/common/form/common-form";
import * as Yup from "yup";
import ArticleLanguageList from "components/common/form/ArticleLanguageList";
import { articleService } from "services/articleService";
import ArticleHirarchy from "components/common/form/ArticleHirarchy";
import { Formik } from "formik";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";

import BrowseButtonArticleImage from "components/BrowseButtonArticleImage";

export default function ArticleForm({  
values,
page,
handleSubmit,
language,
parentId

}) {

  const loadedPage = page;
  const { t } = useTranslation();
  const formikForm = React.useRef(null);
  const [parent_Id, setParent_Id] = useState ("");
  const [toggle,setToggle] = React.useState(false);

  const [ hierarchyType, setHierarchyType] = useState("");
 
  const handleToggle = (event) => {
    setToggle(!toggle)
    values[event.target.name] = !values[event.target.name]
    console.log("Toggles: ", values[event.target.name]);
    console.log("Toggles: ", event.target.name);
  }

  // let validations = {
  //   name: Yup.string().required(t("article_Form_Validation_Name")),
  //   imageUrl: Yup.string().required(t("article_Form_Validation_imageUrl")), 
  //   parentId: Yup.string().required(t("article_Form_Validation_parentId")),   
  //   priority: Yup.string().required(t("article_Form_Validation_priority"))                        
  //             .matches(/^[0-9]+$/, "Must be only digits"),    
  //   languageList: Yup.array().of(
  //     Yup.object().shape({
  //     language: Yup.string().required(t("articleLanguageList_Form_Validation_Language")),
  //     //name: Yup.string().required(t("articleLanguageList_Form_Validation_Name")),
  //     url: Yup.string().required(t("articleLanguageList_Form_Validation_url")),      
  //   })),
  // };

  let validations = {
    //name: Yup.string().required(t("article_Form_Validation_Name")),
    //parentId: Yup.string().required(t("article_Form_Validation_parentId")),
    //imageUrl: Yup.string().required(t("article_Form_Validation_imageUrl")),
    // priority: Yup.string()
    //   .required(t("article_Form_Validation_priority"))
    //   .matches(/^[0-9]+$/, "Must be only digits"),
    languageList: Yup.array().of(
      Yup.object().shape({
        language: Yup.string().required(t("articleLanguageList_Form_Validation_Language")),
        //url: Yup.string().required(t("articleLanguageList_Form_Validation_url")),
      })
    )

  };  

  if (hierarchyType === "MArticle" || hierarchyType === "") {
      validations.name= Yup.string().required(t("article_Form_Validation_Name"));
      validations.parentId= Yup.string().required(t("article_Form_Validation_parentId"));      
      validations.priority= Yup.string()
      .required(t("article_Form_Validation_priority"))
      .matches(/^[0-9]+$/, "Must be only digits");
      validations.imageUrl= Yup.string().required(t("article_Form_Validation_imageUrl"));
      validations.languageList= Yup.array().of(
        Yup.object().shape({      
        language: Yup.string().required(t("articleLanguageList_Form_Validation_Language")),
        url: Yup.string().required(t("articleLanguageList_Form_Validation_url"))
      })
    )
   }
   
   if (hierarchyType === "MCategory"){    
      validations.name= Yup.string().required(t("article_Form_Validation_Name"));
      validations.priority= Yup.string()
      .required(t("article_Form_Validation_priority"))
      .matches(/^[0-9]+$/, "Must be only digits");
        validations.languageList= Yup.array().of(
        Yup.object().shape({
        language: Yup.string().required(t("articleLanguageList_Form_Validation_Language"))
      })
    )    
  }

  if (hierarchyType === "SCategory"){
      validations.name= Yup.string().required(t("article_Form_Validation_Name"));
      validations.parentId= Yup.string().required(t("article_Form_Validation_parentId"));      
      validations.priority= Yup.string()
      .required(t("article_Form_Validation_priority"))
      .matches(/^[0-9]+$/, "Must be only digits");
      validations.imageUrl= Yup.string().required(t("article_Form_Validation_imageUrl"));
      validations.languageList= Yup.array().of(
        Yup.object().shape({      
        language: Yup.string().required(t("articleLanguageList_Form_Validation_Language"))        
      })
    )
   }   

  useEffect(() => {
    articleService.getArticleNameId().then(data => {
      if (data) {
        setParent_Id(data);
      }
    })
  },[]);

  let selectedParentID;
    const selectParentID = value => {
    selectedParentID = value;
    console.log("selectedParentID", selectedParentID)    
  }

  const fields = [
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          {/* <span>
            <h3>{t("artical_Form_hierarchy_Label")}</h3>
          </span> */}
          <ArticleHirarchy
            name={"hierarchy"}
            setFieldValue={setFieldValue}
            values={values["hierarchy"]}   
            setHierarchyType = {setHierarchyType}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-white-l"
      }
    },
    {},
    {
    type: "text",
    name: "name",
    label: t("article_Name"),
    placeholder: t("article_Name"),
    grid: {
      xs: 12,
      sm: 3,
      className: ""
    }
  },
  // {
  //   type: "text",
  //   name: "imageUrl",
  //   label: t("article_image"),
  //   placeholder: t("article_image"),
  //   grid: {
  //     xs: 12,
  //     sm: 3,
  //     className: ""
  //   }
  // },
  hierarchyType !== "MCategory" ?
  {
    type: "select",
    name: "parentId",
    label: t("article_parentId"),
    placeholder: t("article_parentId"),
    option:
    parent_Id.length > 0
          ? parent_Id.map(parent_Id => ({
              value: parent_Id.id,
              label: parent_Id.name
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },           
      customOnChange: selectParentID
  }:null,
  {
    type: "number",
    name: "priority",
    label: t("article_priority"),
    placeholder: t("article_priority"),
    grid: {
      xs: 12,
      sm: 3,
      className: ""
    }
  },
  {},
  hierarchyType !== "MCategory" ?
  {
    type: "text",
    name: "imageUrl",
    label: t("article_image"),
    placeholder: t("article_image"),
    grid: {
      xs: 12,
      sm: 5,
      className: ""
    }
  }:null,
  hierarchyType !== "MCategory" ?
  {
    type: "custom",
    component: ({ values, setFieldValue}, renderFields) => (
      <BrowseButtonArticleImage
        // handleMyClick={handleMyClick}
        //languageValues={language}
        setFieldValue={setFieldValue}
        values={values}
        //toggle={toggle}
        //errors={errors}
        renderFields={renderFields}
      />
    )
  }:null,  
  {
    type: "custom",
    grid: {
      xs: 12,
      sm: 3,
      className: ""
    },
    component: ({}) => (
      <FormControlLabel 
        control={
            <Switch checked={values.isActive} onChange={handleToggle} name="isActive" value={values.isActive} />
        }
        label={t("article_Form_isActive_Label")}
        labelPlacement="top"        
      />
    )
  },
  {
    type: "custom",
    component: ({values, errors }, renderFields) => (
      <ArticleLanguageList
        name="languageList"
        languageValues={language}
        // setFieldValue={setFieldValue}
        values={values}
        toggle={toggle}
        errors={errors}
        renderFields={renderFields}
        setHierarchyType = {setHierarchyType}
        hierarchyType= {hierarchyType}
        
            
      />
    )
  }  
  ];

  console.log("values####",values);
  
  return (
  <Grid container>
    <Grid item xs={12}>
      <PageHeader
        title="Article"
        breadcrumbs={[
          { label: "Dashboard", link: "/dashboard" },
          { label: "Configuration" },
          { label: "Article" }
        ]}
      />
    </Grid>

    <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      > 

      <Grid item>
          {loadedPage == "add" &&
            <CommonForm
              fields={fields}              
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}              
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}                           
              enableReinitialize
              validateOnChange={true}                            
              onSubmit={(values, { resetForm, setSubmitting }) => {
              console.log('onSubmit',values.isActive, values);
              handleSubmit(values, resetForm, setSubmitting);
              }}

              formikRef={formikForm}             
              buttonSize="3"
              buttonPosition="right"                       
            />            
          }
        </Grid>
     </Paper>
  </Grid> // Grid container closed here
  );
}