import React, { useEffect, useContext, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
//import Table from "components/common/table/ControlledPaginationTable";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "../../../components/layout/PageHeader.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { notificationService } from "services/notificationServices";
import { provisioningService } from "../../../services/provisioningServices.js";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { productService } from "services/productServices.js";

import { bannerService } from "services/bannerService";
import { articleService } from "services/articleService.js";
import { language } from "i18next";
import { id } from "date-fns/locale";
import { PinDrop } from "@material-ui/icons";

const dropitems = [
  // {
  //   item: <Link to="/configuration/createproduct">Add Product</Link>
  // }
];

const articleListHierarchy = props => {

  const { dispatch: errorDispatch } = useContext(errorContext);
  const [product, setProduct] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [fetchTag, setFetchTag] = useState([]);
  const [status, setStatus] = useState("loading");

  const [startDate] = React.useState(addDays(new Date(), -2));
  const [endDate] = React.useState(new Date());
  const [pbpData, setPbpData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);

  const [fetchTagStatus, setFetchTagStatus] = useState("loading");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const createError = useState({});
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("currentUser"));

  const [banner, setBanner] = useState([]);
  const [article, setArticle] = useState([]);
  const [articleLangMC, setArticleLangMC] = useState([]);
  const [articleLangSC, setArticleLangSC] = useState([]);
  const [fullArticleList, setFullArticleList] = useState([]);  
  
  const loadArticleLanguageMC = language => {

    articleService.getArticleListData(language).then((data) => {
      console.log("ArticleData", data);

          /* let filteredData = findArticles(data); */

      let filteredData = articleLanguageMC(data);
      
      console.log('filteredData', filteredData);

      setFullArticleList(data);

      /* for (let i = 0; i < data.length; i++) {
        //let key = Object.keys(data[i].name);
        //let tempCode = [];
        let name = data[i].name;
        console.log("name", name);

        let aid = data[i].id; 

        //let salength = data[i].subArticles.length;

        // for (let j = 0; j < key.length; j++) {
        //   //console.log("if condition",data[i].adImageUrl[key[j]].url);
        //   //console.log("status",data[i].adImageUrl[key[j]].url !='')
        //   if (data[i].adImageUrl[key[j]].url != null) {
        //     tempCode.push(key[j]);
        //     console.log("languages", data[i]);
        //   }
        // }  
        //console.log("tempcode", tempCode);    
        //data[i].tempLanguage = tempCode.toString();

        for (let j = 0; j < data[i].subArticles.length; j++) {
          let name = data[i].subArticles[j].name;
          console.log("subartname", name)
        }
      }*/

      //setArticle(data || []);
      //setPageInfo(data.pageInfo);

      //setArticle(filteredData || []);
      setArticleLangMC(filteredData);
      setPageInfo(filteredData.pageInfo);
      setStatus("success");      
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
    
  };

  const articleLanguageMC = (articles, parentName) => {
    let result = [];    
  
    const search = (items, parentName) => {
        items.forEach(item => { 
          //if (item.subArticles.length> 0 && item.imageUrl === null){
            if ((item.subArticles.length === 0 && item.url === "")||
                (item.subArticles.length> 0 && item.imageUrl === null)){
                result.push({
                //...item,  
                id:item.id,        
                name:item.name   
                             
          })}       
            // if (item.subArticles.length === 0 && item.url !== null) {
            //     result.push({
            //         ...item,                                                   
            //         parentName: parentName, 
            //         urlImgWithLink: <a href={item.imageUrl} target="_blank">{item.imageUrl}</a>,  // Adding ImgUrl Hyperlink              
            //         urlWithLink: <a href={item.url} target="_blank">{item.url}</a>  //Adding URL with hyperlink               
            //     });
            // }
            // if (item.subArticles.length > 0) {
            //     search(item.subArticles, item.name); // Pass current item's name as parent name              
            // }       
        });
    };
    //setArticleLangMC(cName); 
    search(articles, parentName);
    console.log('result', result);    
    return result;                                                          
  };

  const loadArticleSC = (ParentID) => {
    
    let resultSC = []; 

    fullArticleList.forEach(article => {
      article.subArticles.forEach(subArticle => {
        if (subArticle.parentId === ParentID) {
          // Initialize variables
          const id = subArticle.id;
          const name = subArticle.name;
    
          // Push an object containing id and name
          resultSC.push({ id, name });
        }
      });
    });

    setArticleLangSC(resultSC);     
    console.log('result', resultSC);    
    return resultSC;
  };


  let viewDropDown = false;

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  function handleSubmit(values, resetForm, setSubmitting) {
    getArticlelist(values);
    setSubmitting(false);
  }

const { t } = useTranslation();
  

const findArticlesWithParentName = (articles, parentName) => {
  let result = [];    

  const search = (items, parentName) => {
      items.forEach(item => { 
        if (item.subArticles.length> 0 && item.imageUrl === null){
        result.push({
          //...item,          
          cName:item.name          
        })}       
          if (item.subArticles.length === 0 && item.url !== null) {
              result.push({
                  ...item,                                                   
                  parentName: parentName, 
                  urlImgWithLink: <a href={item.imageUrl} target="_blank">{item.imageUrl}</a>,  // Adding ImgUrl Hyperlink              
                  urlWithLink: <a href={item.url} target="_blank">{item.url}</a>  //Adding URL with hyperlink               
              });
          }
          if (item.subArticles.length > 0) {
              search(item.subArticles, item.name); // Pass current item's name as parent name              
          }       
      });
  };
  
  search(articles, parentName);
  console.log('result', result);    
  return result;                                                          
};

  function getArticlelist(values) {
    articleService.getArticleList(values.locale).then((data) => {
      console.log("ArticleData", data);

          /* let filteredData = findArticles(data); */

      let filteredData = findArticlesWithParentName(data);
      
      console.log('filteredData', filteredData);
      
      setArticle(filteredData || []);
      setPageInfo(filteredData.pageInfo);
      setStatus("success");      
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
  }

  function getFetchTag() {
    bannerService.getFetchTag().then((data) => {
      setFetchTag(data.fetchTags);   // postman key value also passing here
      setFetchTagStatus("success");
      setStatus("success");
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
  }

  //   function getPbpList(){
  //     productService.getPbpList().then(data =>{
  //       if(data){
  //         setPbpData(data);
  //         setFetchTagStatus("success");
  //       }
  //     });
  //   }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      //getPbpList();
      getFetchTag();
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []);

  const handleDialogClose = () => {
  };

  //   const appType = [
  //     {name:"smart_farms",value:"smart_farms"},
  //     {name:"MOORETAIL",value:"MOORETAIL"}];

  const locale = [
    { name: "HINDI", value: "hi" },
    { name: "ENGLISH", value: "en" },
    { name: "KANNADA", value: "kn" },
    { name: "MARATI", value: "mr" },
    { name: "TELUGU", value: "te" },
    { name: "TAMIL", value: "ta" }];

  // function getFetchTag(){
  //   productService.getFetchTag().then((data) => {
  //     setFetchTagValues(data);
  //     setFetchTagStatus("success");
  //     setStatus("success");
  //   })
  //   .catch(error => {
  //     errorDispatch({
  //       type: "ERROR",
  //       error
  //     });
  //     setStatus("failed");
  //   }); 
  // }

  let values = {
    // fetchTag: "",
    // appType: ""
    locale: "",
    mcName:"",
    scName:""
   
  }

  const fields = [
    {
      type: "select",
      name: "locale",
      label: t("article_List_language_Label"),
      placeholder: t("article_List_language_Label"),
      option: locale.length > 0
        ? locale.map(locale => ({
          value: locale.value,
          label: locale.name
        }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange: loadArticleLanguageMC
    },  
    {},  
    {
      type: "select",
      name: "mcName",
      label: t("article_List_MCName_Label"),
      placeholder: t("article_List_MCName_Label"),
      option:
            articleLangMC.length > 0
          ? articleLangMC.map(articleLangMC => ({
              value: articleLangMC.id,        //  Which field you want pass here
              label: articleLangMC.name              
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        //md: 4,
        className: "mb-2"
      },
      customOnChange: loadArticleSC
    },
    {
      type: "select",         //  singleSelectSearch
      name: "scName",
      label: t("article_List_SCName_Label"),
      placeholder: t("article_List_SCName_Label"),
      option:
      articleLangSC.length > 0
          ? articleLangSC.map(articleLangSC => ({
              value: articleLangSC.id,
              label: articleLangSC.name
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        //md: 4,
        className: "mb-2"
      }
    },   
    
  ];


  const formikForm = React.useRef(null);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      Number(query.get("page")) < pageInfo.totalPages
    ) {
      query.set("page", Number(query.get("page")) + 1);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    }
  };
  const previousPage = () => {
    props.history.goBack();
  };
  const handlePageSize = size => {
    query.set("size", size);
    query.set("page", 0);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString()
    });
  };

  let validations = {    
    locale: Yup.string().required(t("articleList_Form_Validation_Language")),
    mcName: Yup.string().required(t("articleList_Form_Validation_MC")),
    scName: Yup.string().required(t("articleList_Form_Validation_SC"))
  };

  const columns = React.useMemo(
    () => [
      // No need urlName so commented its working
      /* {      
        Header: t("Article Image Url"),
        accessor: "imageUrl",
        //accessor: "urlImgWithLink"
      }, */
      
      {
        Header: "Article Image",
        Cell: ({ row }) => {
          return (
            <>
            {row.original.imageUrl ? (
            <img src={row.original.imageUrl} width="50" height="50" alt="No Image" class="pull-left"/>
            ) : ( null
            )}            
            </> 

            // If image not available showing "No Image" message in red color
            /* <>            
             {row.original.imageUrl ? (
             <img src={row.original.imageUrl} width="50" height="50" alt="Image" />
              ) : (
              <span style={{ color: 'red' }}>No Image</span>              
              )}
            </> */
          )
        }
      },      
      {
        Header: t("Article Name"),
        accessor: "name"
      },
      // {
      //   Header: t("Parent Name"),
      //   accessor: "parentName",
      // },
      // {
      //   Header: t("Category_Name"),
      //   accessor: "cName",           
      // },
      {
        Header: t("Priority"),
        accessor: "priority"
      },
      {
        Header: t("Article Url"),
        accessor: "url",        
        accessor: "urlWithLink"                  
      },
    ],
    []
  );

  const data = React.useMemo(() => [...article]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Article List"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Article List" }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >

        {fetchTagStatus === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
            <CommonForm
              fields={fields}
              submitLabel={t("articleList_Form_Submit_Label")}
              submittingLabel={t("articleList_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
              ) => {
                if (!!errors) {
                  setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="2"
              buttonPosition="right"
              inlineButton={true}
            />
          )}

        {status === "success" ? (
          // <Grid
          //   container
          //   justify="center"
          //   alignItems="center"
          //   style={{ height: "50vh" }}
          // >
          //  <CircularProgress />
          // </Grid>
          <Table
            columns={columns}
            data={data}
            needDropdown={viewDropDown}
            dropItems={dropitems}
          //fileName={"notificationList.xls"}
          />
        ) : null}
        {/* </TemplateForTables> */}
      </Paper>

      {/* <SuccessFeedback
        open={ }
        onClose={handleDialogClose}
        successMessage={t("notification_list_Approve_Success_Message")}
      />
      <FailureFeedback open={failApproveNotification} onClose={handleDialogClose} /> */}

    </Grid>
  );
};

export default articleListHierarchy;
